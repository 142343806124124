import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

const Osi = ({ className, style }: Props) => {
  const data = useStaticQuery(graphql`
    query GET_OSILOGO {
      file(relativePath: { eq: "osi.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Img
      className={className}
      style={style}
      fluid={data.file.childImageSharp.fluid}
      loading="eager"
    />
  )
}

type Props = {
  className?: string
  style?: {}
}

export default Osi
