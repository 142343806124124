import React from "react"
import styled from "@emotion/styled"
import { theme } from "../particles/theme"
import Osi from "../atoms/logos/Osi"

const StyledInfo = styled.div`
  text-align: center;
  position: relative;

  p {
    max-width: 60%;
    margin: -2rem auto 0;
    font-weight: 500;
    font-style: italic;

    @media (max-width: ${theme.breakpoints.tablet}) {
      max-width: 47%;
    }

    @media (max-width: ${theme.breakpoints.mobileMd}) {
      max-width: 100%;
    }
  }

  h3 {
    color: ${theme.colors.homeDepot.primary};
    font-size: 3rem;
  }

  ul {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    padding: 0 0 5rem;
    max-width: 80%;

    @media (max-width: ${theme.breakpoints.tablet}) {
      display: block;
      max-width: 100%;
    }

    li {
      list-style: none;
      display: inline-block;
      position: relative;

      @media (max-width: ${theme.breakpoints.tablet}) {
        display: list-item;
        &:not(:last-of-type) {
          margin-bottom: 2rem;
        }
      }

      &::before {
        content: "";
        position: absolute;
        left: -2.5rem;
        height: 0;
        width: 0;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-left: 14px solid ${theme.colors.homeDepot.primary};

        @media (max-width: ${theme.breakpoints.tablet}) {
          display: none;
        }
      }
    }
  }

  .colored-rectangle {
    background: ${theme.colors.homeDepot.primary};
    width: 100%;
    height: 100%;
    color: white;

    @media (max-width: ${theme.breakpoints.tablet}) {
      width: calc(100% + 10rem);
      margin: 0 -5rem;
    }

    p {
      padding: 1.25rem;
      max-width: 100%;
      text-transform: uppercase;
      font-weight: 700;

      @media (max-width: ${theme.breakpoints.tablet}) {
        padding: 2rem 1.25rem;
      }
    }
  }
`

const Information = ({ className }) => {
  return (
    <StyledInfo className={className}>
      <Osi
        className="display-mobile"
        style={{ height: "100px", width: "100px", marginTop: "2rem" }}
      />
      <p>
        Find the OSI QUAD&reg; or QUAD&reg; Max colored caulk to match your
        window, door, or siding job.
      </p>
      <h3>SEARCH 3 WAYS</h3>
      <ul>
        <li>By three-digit QUAD or QUAD Max color</li>
        <li>By QUAD or QUAD Max IDH number</li>
        <li>By manufacturer</li>
      </ul>
      <div className="colored-rectangle">
        <p>
          You have access to 300+ colors providing 9,200 color matches for over
          300 primary building material manufacturers
        </p>
      </div>
    </StyledInfo>
  )
}

export default Information
