export const theme = {
  colors: {
    bodyFont: "#666",
    homeDepot: {
      primary: "#f96302",
      hover: "#c14c00",
      black: "#333333",
      gray: "#777777",
      medGray: "#CCCCCC",
      ltGray: "#EEEEEE",
      link: "#3E7697",
    },
  },
  breakpoints: {
    mobileSm: "499px",
    mobileMd: "768px",
    tablet: "1072px",
    desktop: "1200px",
  },
}
