import React from "react"
import styled from "@emotion/styled"

const StyledContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 5rem;
`

const Content = ({ children, className, style }) => {
  return (
    <StyledContent className={className} style={style || undefined}>
      {children}
    </StyledContent>
  )
}

export default Content
